/*
 * @Author: wangqs 
 * @description 测试模块
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-24 13:36:23
 */
<template>
  <div class="page-box box">
    <p>
      <van-icon
        class="icon"
        name="checked"
      />
    </p>
    <p class="tip-text">
      资料上传完毕请等待审核
    </p>
    <van-button
      plain
      class="btn-text"
      type="primary"
      color="#51B464"
      @click="backHome"
    >
      返回
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      isGlobalPage: false
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    backHome () {
      let backlen = history.length-2;
      history.go(-backlen);
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-top: 180px;
  text-align: center;
  padding-bottom: 10px;
  .icon {
    font-size: 72px;
    color: rgb(81, 180, 100)
  }
  .tip-text {
    margin-top: 22px;
    font-size: 16px;
    color: #282734;
  }
  .btn-text {
    width: 160px;
    line-height: 36px;
    height: 36px;
    border-radius: 18px;
    margin-top: 28px
  }
  .tip-text-b {
    margin-top: 54px;
    font-size: 26px;
    color: #646464;
  }
}
</style>
